.footerBottomContainer{
    /* background-color: pink; */
    background-color: #002129;
    /* padding: 10px 0px 10px 0px; */
    /* text-align: center; */
    display: flex;
    justify-content: center;
    /* border-top: 1px solid gainsboro; */
    width: 100%;
}

.footerCopyrightContainer{
    border-top: 1px solid gainsboro;
    /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(220,220,220,1) 50%, rgba(255,255,255,1) 100%); */
    width: 90%;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
}

.footerDataOuterContainer{
    background-color: #002129;
    width: 100%;
    padding: 3%;
    display: flex;
    justify-content: center;
}

.footerDataInnerContainer{
    /* background-color: #8fb0b4; */
    width: 85%;
    max-width: 1550px;
    /* padding: 5%; */
}



.footerText:hover{
    text-decoration: underline;
    cursor: pointer;
    color: white;
}