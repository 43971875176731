.cardContainer{
    background-color: #ECECEC;
    /* background-color: #169c49; */
    width: 100%;
    padding: 3%;
    /* border: 2px solid grey; */
    border-radius: 20px;
}

.cardHeadingContainer{
    /* background-color: yellowgreen; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.cardHeadingCircleContainer{
    /* background-color: aquamarine; */
    width: 92%;
    position: relative;
}

.cardHeadingCircle{
    background-color: #16939c;
    height: 4rem;
    width: 4rem;
    border-radius: 1000px;
    position: absolute;
    top: -40px;
    left: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardHeadingBorder{
    border: 0.4vh solid black;
    height: 4rem;
    width: 4rem;
    border-radius: 1000px;
    position: absolute;
    z-index: 1;
    top: -48px;
    left: -27px;
    transition: all 0.7s;
}

.cardHeadingNumber{
    transform: scale(0.7);
    transition: all 0.7s;
    padding: 2% 3% 0% 0%;
    color:#16939ca9;
    /* background-color: yellowgreen; */
}

.cardContainer:hover{
    .cardHeadingNumber{
        transform: scale(1.5);
        /* padding: 0% 2% 0% 0%; */
        /* font-size: 40px; */
    }
    .cardHeadingBorder{
        top: -40px;
        left: -40px;
    }
}

.serviceLearnMoreText{
    cursor: pointer;
}

.serviceLearnMoreText:hover{
    cursor: pointer;
    text-decoration: underline;
}

/* ********************************************** */
.cardContainer4{
    background-color: #ECECEC;
    /* background-color: brown; */
    width: 100%;
    padding: 1%;
    /* border: 2px solid grey; */
    border-radius: 20px;
}

.cardLastHeadingContainer{
    /* background-color: yellowgreen; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.cardLastHeadingCircleContainer{
    /* background-color: aquamarine; */
    width: 95%;
    position: relative;
}

.cardLastHeadingCircle{
    background-color: #16939c;
    height: 4rem;
    width: 4rem;
    border-radius: 1000px;
    position: absolute;
    top: -35px;
    left: -33px;
}

.cardLastHeadingBorder{
    border: 0.4vh solid black;
    height: 4rem;
    width: 4rem;
    border-radius: 1000px;
    position: absolute;
    z-index: 1;
    top: -43px;
    left: -20px;
    transition: all 0.7s;
}

.cardLastHeadingNumber{
    transform: scale(0.7);
    padding: 2% 3% 0% 0%;
    transition: all 0.7s;
    color:#16939ca9;
    /* background-color: yellowgreen; */
}

.cardContainer4:hover{
    .cardLastHeadingNumber{
        transform: scale(1.5);
        /* padding: 2%; */
        /* font-size: 40px; */
    }
    .cardLastHeadingBorder{
        top: -35px;
        left: -33px;
    }
}

/* .card{
    background-color: yellowgreen;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
} */

/* .backCircle{
    
} */

/* .borderCircle{
 
} */
/* 
.card:hover{
    .headingNumber{
    transform: scale(1.5);
    font-size: 50px;
}
    .borderCircle{
        top: -25px;
        left: -25px;
    }
} */

