.BookingButton{
    background-color: rgba(255, 0, 0, 0.096);
    /* opacity: 0.1; */
    color: rgb(109, 0, 0);
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    font-size: medium;
    /* font-weight:bold; */
}

.BookingButton:hover{
    background-color: rgba(255, 0, 0, 0.295);
}

.pricingChip{
    /* border-radius: 10px; */
    /* background-color: #63898c; */
    /* margin-bottom: 4px; */
    /* padding: 5px; */
}

.pricingChip span{
    /* color: #00131C; */
    font-weight: bold;
    font-family: open sans;
    font-size: small;
    margin: 2px;
}