

.webServiceHeroContainer{
    width: 100%;
    /* background-color: yellowgreen; */
}

.webServiceOverlay{
    display: flex;
    justify-content: end;
    align-items: center; 
    /* background-color: tomato; */
    position: relative;
}

.webServiceHeroImgContainer{
    /* position: relative; */
    /* background-color: springgreen; */
    /* height: 30rem; */
    width: 100%;
    /* background-color: hsla(0, 0%, 0%, 0.521); */
    /* padding: 5%; */
}

.webServiceHeroContentContainer{
    /* background-color: rgb(81, 7, 83); */
    padding: 2% 0% 2% 0%;
    margin: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    /* margin-left: 60px; */
    display: flex;
    justify-content: center;
    /* align-items: end; */
}

.webServiceHeroContentInnerContainer{
    /* background-color: #609d9f; */
    width: 85%;
    max-width: 1536px;
}

.webServiceMainContainer{
    /* background-color: tomato; */
    width: 100%;
    padding: 3% 0% 3% 0%;

}

.webServiceSecondaryContainer{
    /* background-color: #9210e9; */
    width: 100%;
    padding: 0% 2%;
}

.webServiceIntroTextContainer{
    /* background-color: chocolate; */
    width: 100%;
    padding: 3% 0% 0% 0%;
}

.webServiceIntroImageContainer{
    /* background-color: #801c67; */
    /* max-width: 1536px; */
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    /* padding: 1%; */
}

.webServiceIntroImage{
    width: 80%;
    /* height: 30rem; */
    border: 1px solid black;
    border-radius: 1vh;
    /* display: flex; */
    /* justify-content: center; */
}

.webServiceScrollingHeadingContainer{
    padding: 2%;
}

.webServiceScrollingContainer{
    background-color: #16939c;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2% 0%;
}

/* .webServiceProcessTextContainer{
    background-color: violet;
} */

.webServiceStepperContainer{
    /* background-color: sienna; */
    padding: 0% 2%;
}

.webServiceStepContainer{
    /* background-color: navy; */
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    /* position: relative; */
}

.webServiceStepCircle1{
    /* background-color: #5bd479; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5%; */
    /* border-radius: 50%; */
    /* position: absolute; */
}

.webServiceLeftContainer{
    /* background-color: rgb(110, 22, 22); */
    padding: 2%;
}

.webServiceRightContainer{
    /* background-color: rosybrown; */
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.webServiceChooseContainer{
 /* background-color: #10a4e9;    */
 width: 100%;
}

/* .webServicePricingTextContainer{
    background-color: chocolate;
    width: 100%;
    padding: 1% 0%;
}

.webServicePricingPlanContainer{
    background-color: darkgreen;
    padding: 2%;
    width: 100%;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.webServicePricingLeftContainer{
    background-color: red;
    border-right: 1px solid gray;
    padding: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.webServicePricingRightContainer{
    background-color: pink;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3%;
} */

.box1{
    /* background-color: yellowgreen; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2% 1%; 
}

@media only screen and (max-width: 600px) {
    .webServiceIntroImage{
        width: 100%;
        height: 20vh;
        border: 3px solid black;
        border-radius: 2vh;
    }

    /* .webServiceScrollingContainer{
        background-color: #16939c;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2% 0%;
    } */

    /* .webServicePricingRightContainer{
        background-color: rgb(192, 204, 255);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3%;
    }

    .webServicePricingLeftContainer{
        background-color: red;
        border-bottom: 1px solid gray;
        padding: 5%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    } */

    .box1{
        /* background-color: yellowgreen; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 3%; 
    }
  }
