.ProjectListOuterContainer{
    background-color: hsla(182, 93%, 6%, 0.4);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 0% 3% 0%;
}

.ProjectListInnerContainer{
     background-color: #609d9f;
     padding: 0px;
     width: 85%;
     max-width: 1550px;
     /* display: flex;
     justify-content: center; */
}

.projectNumberStyle{
    font-size: 2.5rem;
    color: black;
    font-weight: bold;

}