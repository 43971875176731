.Button{
    color: #fff;
}
.Button:hover{
    color: #16939C;
    border-bottom: 1px solid #16939C;
    
    .navbarButtonIcon:hover{
        color: #16939C;
    }
   /* .navbarquoteButton:hover{
    border: 1px solid #EEE8A9;
   } */
}


.navbarButton{
    border: 1px solid #fff;
    background-color: white;
}


.navbarButtonIcon{
    color: #e6f4f1;
}

.navbarButtonIcon:hover{
    color: #16939C;
}

/*.menuItemText{
    color: white;
    font-size: small;
} */

