.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    /* padding: 5px; */
  }
