.homeProjectContainer{
    background-color: cornflowerblue;
    /* padding: 2%; */
    /* border-radius: 10px; */
    width: 100%;
    height: 25rem;
    padding: 0px;
}

.projectCardHorizontal{
    /* background-color: #bbb; */
    width: 100%;
    height: 30rem;
    position: absolute;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(51,135,156,1) 87%, rgba(18,88,105,1) 100%);
    /* background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(51,135,156,1) 87%, rgba(18,88,105,1) 100%); */
    background: linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(51,135,156,1) 87%, rgba(18,88,105,1) 100%);
    transition:all .9s ease;
    transform: rotateX(90deg);
    border-radius: 9px;
    display: flex;
    /* bottom: 0; */
    flex-direction: column;
    justify-content: end;
}

.projectCardHorizontal{
    padding: 8%;
    font-family: 'Helvetica Neue';
}

/* .imgzoom{
  transform: scale(1);
    transition: all 0.7s;
} */
/* 
.imageContainer{
  overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
}

image{
  transition: all 0.7s;
} */


.homeProjectContainer:hover{
    .projectCardHorizontal{
        /* -webkit-transform:rotateX(179.9deg);
        -moz-transform:rotateX(179.9deg); */
        transform:rotateX(0deg);
    }

    /* .imageZoom:hover{
      transform: scale(1.30);
    } */
  }



@media only screen and (max-width: 600px) {
  .projectCardHorizontal{
    /* background-color: #bbb; */
    width: 100%;
    height: 30rem;
    position: absolute;
    background: rgb(255,255,255);
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
    transition:all .9s ease;
    transform: rotateX(0deg);
    border-radius: 9px;
    display: flex;
    /* bottom: 0; */
    flex-direction: column;
    justify-content: end;
}
  }

  @media only screen and (max-width: 800px) {
    .projectCardHorizontal{
      /* background-color: #bbb; */
      width: 100%;
      height: 30rem;
      position: absolute;
      background: rgb(255,255,255);
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0.0003598851650035151) 0%, rgba(122,196,201,1) 87%, rgba(122,196,201,1) 100%);
      transition:all .9s ease;
      transform: rotateX(0deg);
      border-radius: 9px;
      display: flex;
      /* bottom: 0; */
      flex-direction: column;
      justify-content: end;
  }
    }
