.OuterContainer{
    /* background-color: hsla(182, 93%, 6%, 0.4); */
    /* background: #fafaf7; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 0% 3% 0%;
}

.InnerContainer{
    /* background-color: #609d9f; */
    padding: 0px;
    width: 90%;
    max-width: 1550px;
    /* display: flex;
    justify-content: center; */
}

.homeHeroSection{
    /* background-image: url('../../Assets/Image1.png'); */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    /* height: fit-content; */
    width: 100%;
    /* background-color: yellowgreen; */
}

.homeHeroOverlay{
    /* background-color: rgba(0,0,0,0.5);
    height: 100vh;
    width: 100%;*/
    display: flex;
    justify-content: end;
    align-items: center; 
    /* background-color: tomato; */
    position: relative;
    /* padding: 20px; */
}

.homeImgContainer{
    position: relative;
    /* background-color: springgreen; */
    /* height: 20rem; */
    width: 100%;
    /* background-color: hsla(0, 0%, 0%, 0.521); */
    /* padding: 5%; */
}
/* 
@media only screen and (max-width: 600px) {
    .homeImgContainer{
        position: relative;
        height: 30rem;
        width: 100%;
    }
  } */

.homeHeroContent{
    /* background-color: yellowgreen; */
    padding: 3% 3% 3% 3%;
    margin: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    /* margin-left: 60px; */
    display: flex;
    justify-content: center;
    /* align-items: end; */
}

.homeHeroInnerContainer{
    /* background-color: #609d9f; */
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeHeroButtonContainer{
    /* background-color: rgba(145, 255, 0, 0.26); */
    display: flex;
    flex-direction: row;
    /* justify-content: s; */
    padding: 2% 0% 2% 0%;
    width: 100%;
    margin-left: 7px;
}

.homeHeroButtonContainer Button{
    /* -webkit-transition: 0.4s ease-out; */
      /* -moz-transition:  0.4s ease-out; */
      /* transition:  transform 0.5s ease-out; */
      transition: 0.5s  ease;
}

.homeHeroButtonContainer Button:hover{
    .homeHeroButtonIcon
    {
        transform: rotateZ(360deg);
        transition: 0.5s ease;
    /* -webkit-transform: rotateZ(720deg); */
      /* -moz-transform: rotateZ(720deg); */
      /* transform: rotateZ(720deg); */
 } 
 .homeHeroButtonIcon2{
        transform: rotateZ(45deg);
        transition: 0.5s ease;
 }
}

.homeButtonCnotainer Button{
  transition: 0.5s  ease;
}

.homeChip{
    /* border-radius: 10px; */
    /* background-color: #63898c; */
    margin-bottom: 4px;
}

.homeChip span{
    color: #16939C;
    font-weight:700;
    font-family: open sans;
    font-size: small;
    margin: 0px;
    flex-wrap: wrap;
}

.homeAboutClients{
    /* background-color: yellowgreen; */
    padding: 2% 1% 2% 1%;
    width: 90%;
    /* max-width: 1550px; */
    display: flex;
    align-items: center;
    border-top: 0.2vh solid gainsboro;
    border-bottom: 0.2vh solid gainsboro;
    margin-top: 2%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: start;
    /* justify-items: start; */
}

.homeUSPPoints{
    /* border: 1px solid cyan; */
    padding: 4px 0px 0px 0px;
}

.homeUSPOuterContainer{
    /* background-color: hsla(182, 93%, 6%,1); */
    /* background: #fafaf7; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 0% 0% 0%;
}

.homeButtonCnotainer Button:hover{

 .homeHeroButtonIcon2
    {
        transform: rotateZ(45deg);
        transition: 0.5s ease;
 }
}

.homeButtonCnotainer Button{
  transition: 0.5s  ease;
}

.homeButtonCnotainer{
    /* background-color: #031618; */
    display: flex;
    justify-content: center;
}

.homeSerivesLearnText{
    display: flex;
    justify-content: end;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;
    /* background-color: yellowgreen; */
    width: fit-content;
    margin-bottom: 10px;
}

.homeServicesLearn:hover{
    text-decoration: underline
}

.homeServicesPoints{
     border: 1px solid #00131C;
     padding: 4%;
     border-radius: 20px;
}

.pricingContainer{
    /* background-color: violet; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4%;

}

.homeContactTimingContainer{
    /* background-color: yellow; */
    padding: 4% 0% 4% 0%;
    border-bottom: 0.2vh solid gainsboro;
    margin-top: 3%;
    /* display: flex; */
    /* flex-direction: ; */
}

.homeQuote{
    /* padding: 2%; */
    background-image: '../../Assets/Image1.png';
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 10vh;
    /* background-color: #00131C; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.pictureContainer{
    /* background-color: hsla(182, 93%, 6%, 0.4); */
    /* background: #fafaf7; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0% 5% 0%;
    /* margin-top: 3%; */
    background-image: url('../../Assets/Image2.png');
    /* background-position; */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
}