
.clientsMainContainer {
    position: relative;
    width: 100%;
    /* background-color: yellow; */
    overflow: hidden;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
  
  .m-scroll {
    /* overflow: hidden; */
    /* height: ; */
    /* white-space: nowrap; */
    /* animation: scrollText 20s infinite linear; */
    /* margin: 0;
    font-size: 0; */
    display: flex;
    justify-content: space-between;
    width: fit-content;
    /* background-color: aquamarine; */
  }
  
  span {
    display: flex;
    align-items: center;
    /* background-color: violet; */
    margin: 0vh 5vh 0vh 5vh;
    padding: 0;
    color: white;
  }
  
  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }