.formContainer{
    /* background-color: yellowgreen; */
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

.formContainer form{
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: start;
}

.formContainer input, 
.formContainer textarea{
    border: none;
    padding: 0.8em;
    width: 100%;
    border-radius: 5px;
    font-size: 1em;
    background-color: #e4e4e4;
}

.formContainer textarea{
    resize: none;
    height: 8em;
    /* font-family: sans open; */
}

.formContainer input:focus,
.formContainer textarea:focus{
    outline: none;
}

.formContainer input::placeholder,
.formContainer textarea::placeholder{
    color: #696969;
}

.formContainer button{.formContainer{
    /* background-color: yellowgreen; */
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    box-sizing: border-box;
}

.formContainer form{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formContainer input, 
.formContainer textarea{
    border: none;
    padding: 0.8em;
    width: 100%;
    border-radius: 5px;
    font-size: 1em;
    background-color: #e4e4e4;
    margin-bottom: 10px;
}

.formContainer textarea{
    resize: none;
    height: 8em;
    /* font-family: sans open; */
}

.formContainer input:focus,
.formContainer textarea:focus{
    outline: none;
    box-shadow: 0 0 5px #FF8A43;
}

.formContainer input::placeholder,
.formContainer textarea::placeholder{
    color: #696969;
}

.formContainer button{
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0.8em;
    background-color: #FF8A43;
    color: #fff;
    border-radius: 8px;
    font-size: medium;
    transition: 0.3s;
    margin-top: 10px;
}

.formContainer button:hover{
    background-color: #e6681c;
}
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0.8em;
    background-color: #FF8A43;
    color: #fff;
    border-radius: 8px;
    font-size: medium;
    transition: 0.3s;
    /* display: flex;
    justify-content: center; */
}

.formContainer button:hover{
    background-color: #e6681c;
}